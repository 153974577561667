import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps extends React.PropsWithChildren {
    className?: string;
    værdi?: string | IconDefinition;
    vedKlik?: () => void;
    tilføjVærdi?: (value: string) => void;
    children?: React.ReactNode;
}

export function TrykKnap(props: IProps) {
    const { værdi, children, vedKlik, tilføjVærdi, className } = props;

    function hentIndhold() {
        if (children) {
            return <>{children}</>;
        }
        if (værdi) {
            if (typeof (værdi) === "string") {
                return <>{værdi}</>;
            }
            else {
                return <FontAwesomeIcon icon={værdi} />;
            }
        }
    }

    function hånterKlik() {
        if (vedKlik) {
            vedKlik();
        }
        else if (tilføjVærdi && værdi && typeof (værdi) === "string") {
            tilføjVærdi(værdi);
        }
    }

    return (
        <button
            type="button"
            className={"knap " + className}
            onClick={hånterKlik}>
            {hentIndhold()}
        </button>
    );
}