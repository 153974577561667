import React from "react";
import { faBackspace, faTrashCan, faSquareRootVariable, faPlusMinus, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TrykKnap } from "./TrykKnap";

import "./app.css";

const rounding = 10000000000;

interface Historie {
  førsteVærdi: string;
  andenVærdi: string;
  regneTegn: string;
  resultat: string;
  resultatTal: number;
}

export function App() {
  const [førsteVærdi, sætFørsteVærdi] = React.useState<string>("");
  const [andenVærdi, sætAndenVærdi] = React.useState<string>("");
  const [resultat, sætResultat] = React.useState<string>("");
  const [regneTegn, sætRegneTegn] = React.useState<string>("");
  const [historik, sætHistorik] = React.useState<Array<Historie>>([]);
  const [indeks, sætIndeks] = React.useState<number>(0);

  function tilføjVærdi(input: string) {
    if (!resultat) {
      sætAndenVærdi(andenVærdi + input);
    }
  }

  function sletEtTegn() {
    if (!resultat) {
      if (andenVærdi.length > 0) {
        sætAndenVærdi(andenVærdi.substring(0, andenVærdi.length - 1));
      }
    }
  }

  function tilføjKomma() {
    if (andenVærdi.indexOf(",") < 0) {
      tilføjVærdi(",");
    }
  }

  function sletAlt() {
    sætFørsteVærdi("");
    sætAndenVærdi("");
    sætRegneTegn("");
    sætResultat("");
    sætIndeks(0);
  }

  function udregn() {
    const førsteTal = parseFloat(førsteVærdi.replace(",", "."));
    const andetTal = parseFloat(andenVærdi.replace(",", "."));
    let resultatTal = andetTal;

    switch (regneTegn) {
      case "+": {
        resultatTal = førsteTal + andetTal;
        break;
      }
      case "-": {
        resultatTal = førsteTal - andetTal;
        break;
      }
      case "*": {
        resultatTal = førsteTal * andetTal;
        break;
      }
      case "/": {
        resultatTal = førsteTal / andetTal;
        break;
      }
      case "x^y": {
        resultatTal = førsteTal ** andetTal;
        break;
      }
      case "y_^x": {
        resultatTal = andetTal ** (1 / førsteTal);
        break;
      }
    }

    resultatTal = Math.round((resultatTal + Number.EPSILON) * rounding) / rounding;

    const nytResultat = resultatTal.toString().replace(".", ",");

    sætResultat(nytResultat);

    sætHistorik([{
      førsteVærdi: førsteVærdi,
      andenVærdi: andenVærdi,
      resultat: nytResultat,
      regneTegn: regneTegn,
      resultatTal: resultatTal
    },
    ...historik]);
  }

  function enParameterFunction(regneFunction: string) {
    if (resultat) {
      return;
    }

    if (førsteVærdi) {
      return;
    }

    if (regneTegn) {
      return;
    }

    if (!andenVærdi) {
      return;
    }

    const tal = parseFloat(andenVærdi.replace(",", "."));
    let resultatTal = NaN;
    let suffiks = "";

    switch (regneFunction) {
      case "sin": {
        resultatTal = Math.sin(tal);
        break;
      }
      case "cos": {
        resultatTal = Math.cos(tal);
        break;
      }
      case "tan": {
        resultatTal = Math.tan(tal);
        break;
      }
      case "ln": {
        resultatTal = Math.log(tal);
        break;
      }
      case "log": {
        resultatTal = Math.log10(tal);
        break;
      }
      case "x/2": {
        resultatTal = tal / 2;
        break;
      }
      case "2x": {
        resultatTal = tal * 2;
        break;
      }
      case "%": {
        resultatTal = tal * 100;
        suffiks = "%";
        break;
      }
      case "^-x": {
        resultatTal = Math.sqrt(tal);
        break;
      }
      case "n!": {
        resultatTal = Math.floor(tal);

        for (let n = resultatTal - 1; n > 1; --n) {
          resultatTal *= n;
        }

        break;
      }
    }

    resultatTal = Math.round((resultatTal + Number.EPSILON) * rounding) / rounding;

    const nytResultat = resultatTal.toString().replace(".", ",") + suffiks;

    sætRegneTegn(regneFunction);
    sætResultat(nytResultat);

    sætHistorik([{
      førsteVærdi: førsteVærdi,
      andenVærdi: andenVærdi,
      resultat: nytResultat,
      regneTegn: regneFunction,
      resultatTal: resultatTal
    },
    ...historik]);
  }

  function sætTegn(tegn: string) {
    if (!regneTegn) {
      if (andenVærdi) {
        sætRegneTegn(tegn);
        sætFørsteVærdi(andenVærdi);
        sætAndenVærdi("");
      }
    }
  }

  function skiftMellemPlusOgMinus() {
    if (!resultat) {
      if (andenVærdi) {
        if (andenVærdi.startsWith("-")) {
          sætAndenVærdi(andenVærdi.substring(1, andenVærdi.length));
        }
        else {
          sætAndenVærdi("-" + andenVærdi);
        }
      }
    }
  }

  function tilføjPi() {
    if (!resultat) {
      if (!andenVærdi) {
        sætAndenVærdi(Math.PI.toString().replace(".", ","));
      }
    }
  }

  function tilføjE() {
    if (!resultat) {
      if (!andenVærdi) {
        sætAndenVærdi(Math.E.toString().replace(".", ","));
      }
    }
  }

  function findBrøk() {
    if (resultat) {
      return;
    }

    if (førsteVærdi) {
      return;
    }

    if (regneTegn) {
      return;
    }

    const resultatTal = parseFloat(andenVærdi.replace(",", "."));
    let tal = resultatTal;
    let erEtMinusTal = false;

    if (tal < 0) {
      erEtMinusTal = true;
      tal = -tal;
    }

    let decimal = tal - Math.floor(tal);

    tal = Math.floor(tal);

    decimal = Math.round((decimal + Number.EPSILON) * rounding);

    let nytResultat;

    if (decimal != 0) {
      const g = gcd(decimal, rounding);
      decimal = Math.round((decimal / g + Number.EPSILON) * rounding) / rounding;
      const c = Math.round((rounding / g + Number.EPSILON) * rounding) / rounding;

      if (tal != 0) {
        nytResultat = (erEtMinusTal ? "-" : "") + tal + (erEtMinusTal ? "-" : "+") + + decimal + "/" + c;
      }
      else {
        nytResultat = (erEtMinusTal ? "-" : "") + decimal + "/" + c;
      }
    }
    else {
      nytResultat = (erEtMinusTal ? "-" : "") + tal.toString();
    }

    const nytRegnetegn = "a+b/c";

    sætRegneTegn(nytRegnetegn);

    sætResultat(nytResultat);

    sætHistorik([{
      førsteVærdi: førsteVærdi,
      andenVærdi: andenVærdi,
      resultat: nytResultat,
      regneTegn: nytRegnetegn,
      resultatTal: resultatTal
    },
    ...historik]);
  }

  function gcd(a: number, b: number): number {
    if (a === 0) {
      return NaN;
    }

    if (b === 0) {
      return NaN;
    }

    if (a < 0) {
      return gcd(-a, b);
    }

    if (b < 0) {
      return gcd(a, -b);
    }

    if (a < b) {
      return gcd(b, a);
    }

    const c = a % b;

    if (a % b == 0) {
      return b;
    }
    else {
      return gcd(b, c);
    }
  }

  function hentSidsteVærdier() {
    if (historik.length === 0) {
      return;
    }

    if (resultat) {
      return;
    }

    const historie = historik[indeks];
    sætIndeks((indeks + 1) % historik.length);
    sætAndenVærdi(historie.resultatTal.toString().replace(".", ","));
  }

  return (
    <div className="ydre-kasse">
      <div className="historik">
        {historik.map((historie, indeks) => {
          const { førsteVærdi, andenVærdi, regneTegn, resultat } = historie;
          return (
            <div className="svaret" key={indeks}>
              <div className="førsteVærdi">{førsteVærdi}</div>
              <div className="regneTegn">{regneTegn}</div>
              <div className="andenVærdi">{andenVærdi}</div>
              <div className="resultat">{resultat}</div>
            </div>
          );
        })}
      </div>
      <div className="svaret">
        <div className="førsteVærdi">{førsteVærdi}</div>
        <div className="regneTegn">{regneTegn}</div>
        <div className="andenVærdi">{andenVærdi}</div>
        <div className="resultat">{resultat}</div>
      </div>
      <div className="knapper">
        <TrykKnap værdi="Sin" vedKlik={() => enParameterFunction("sin")} />
        <TrykKnap værdi="Cos" vedKlik={() => enParameterFunction("cos")} />
        <TrykKnap værdi="Tan" vedKlik={() => enParameterFunction("tan")} />
        <TrykKnap værdi="Ln" vedKlik={() => enParameterFunction("ln")} />
        <TrykKnap værdi="Log" vedKlik={() => enParameterFunction("log")} />
        <TrykKnap værdi="x/2" vedKlik={() => enParameterFunction("x/2")} />
        <TrykKnap værdi="2x" vedKlik={() => enParameterFunction("2x")} />
        <TrykKnap vedKlik={() => sætTegn("x^y")} >
          <var>x<sup>y</sup></var>
        </TrykKnap>
        <TrykKnap vedKlik={() => sætTegn("y_^x")} >
          <var><sup>y</sup><FontAwesomeIcon icon={faSquareRootVariable} /></var>
        </TrykKnap>
        <TrykKnap værdi="%" vedKlik={() => enParameterFunction("%")} />
        <TrykKnap værdi={faBackspace} vedKlik={sletEtTegn} />
        <TrykKnap værdi={faTrashCan} vedKlik={sletAlt} />
        <TrykKnap værdi={faPlusMinus} vedKlik={() => skiftMellemPlusOgMinus()} />
        <TrykKnap værdi={faSquareRootVariable} vedKlik={() => enParameterFunction("^-x")} />
        <TrykKnap værdi="&pi;" vedKlik={() => tilføjPi()} />
        <TrykKnap værdi="1" tilføjVærdi={tilføjVærdi} />
        <TrykKnap værdi="2" tilføjVærdi={tilføjVærdi} />
        <TrykKnap værdi="3" tilføjVærdi={tilføjVærdi} />
        <TrykKnap værdi="/" vedKlik={() => sætTegn("/")} />
        <TrykKnap værdi="a+b/c" vedKlik={() => findBrøk()} />
        <TrykKnap værdi="4" tilføjVærdi={tilføjVærdi} />
        <TrykKnap værdi="5" tilføjVærdi={tilføjVærdi} />
        <TrykKnap værdi="6" tilføjVærdi={tilføjVærdi} />
        <TrykKnap værdi="*" vedKlik={() => sætTegn("*")} />
        <TrykKnap værdi="n!" vedKlik={() => enParameterFunction("n!")} />
        <TrykKnap værdi="7" tilføjVærdi={tilføjVærdi} />
        <TrykKnap værdi="8" tilføjVærdi={tilføjVærdi} />
        <TrykKnap værdi="9" tilføjVærdi={tilføjVærdi} />
        <TrykKnap værdi="-" vedKlik={() => sætTegn("-")} />
        <TrykKnap værdi="e" vedKlik={() => tilføjE()} />
        <TrykKnap værdi="," vedKlik={() => tilføjKomma()} />
        <TrykKnap værdi="0" tilføjVærdi={tilføjVærdi} />
        <TrykKnap værdi="=" vedKlik={udregn} />
        <TrykKnap værdi="+" vedKlik={() => sætTegn("+")} />
        <TrykKnap værdi={faFloppyDisk} vedKlik={() => hentSidsteVærdier()} />
      </div>
    </div>
  );
}

